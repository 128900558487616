import React, { useState, useEffect } from "react";
import { Drawer, Toolbar, IconButton, Typography, MenuItem, AppBar, Link, Button } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { firestore } from './Firebase';
import Logo from './../inewsLogo.png';

export default function NavBar() {
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false
    });
    const [headersData, setHeadersData] = useState([
        {
            label: "HeadLines",
            href: "/",
        },
    ]);

    const logo = <img src={Logo}></img>

    const { mobileView, drawerOpen } = state;

    const fetchCategories = async () => {
        firestore.collection('categories').get().then(collections => {
            const categories = collections.docs.map(doc => doc.data().type)[0];
            const _headersData = categories.map(category => ({ label: category, href: "/NewsList/" + category }));
            setHeadersData(prevState => prevState.concat(_headersData));
        })
    }

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        fetchCategories();
    }, []);

    const displayDesktop = () => {
        return (
            <Toolbar>
                <img
                    style={{
                        heigth: "20px",
                        width: "40px"
                    }}
                    src={
                        Logo
                    }
                    alt="INewsTamil Logo"
                />
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="h1">
                        INewsTamil
                    </Typography>
                </div>
                {getMenuButtons()}
            </Toolbar>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: true }));
        const handleDrawerClose = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: false }));
        return (
            <Toolbar>
                <IconButton
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
                    <div>{getDrawerChoices()}</div>
                </Drawer>
                <img
                    style={{
                        heigth: "20px",
                        width: "40px"
                    }}
                    src={
                        Logo
                    }
                    alt="INewsTamil Logo"
                />
                <div>INewsTamil</div>
            </Toolbar >
        );
    };

    const getDrawerChoices = () => {
        return headersData.map(({ label, href }) => {
            return (
                <Link
                    {...{
                        component: RouterLink,
                        to: href,
                        color: "inherit",
                        style: { textDecoration: "none" },
                        key: label,
                    }}
                >
                    <MenuItem>{label}</MenuItem>
                </Link>
            );
        });
    };

    const getMenuButtons = () => {
        return headersData.map(({ label, href }) => {
            return (
                <Button
                    {...{
                        key: label,
                        color: "inherit",
                        to: href,
                        component: RouterLink,
                    }}
                >
                    {label}
                </Button>
            );
        });
    };

    return (
        <AppBar>
            {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
    );
}