import { Grid } from '@material-ui/core';
import React from 'react'
import NavBar from './Components/NavBar';
import Footer from './Components/Footer';

export default function Layout(props) {
    return (
        <>
            <Grid container key="Header">
                <Grid item md={12} sm={12}>
                    <NavBar />
                </Grid>
            </Grid>
            <Grid container key="Body">
                {/* <Grid item md={12} sm={12} xs={12}>
                </Grid> */}
                <Grid item md={12} sm={12} xs={12}>
                    {props.children}
                </Grid>
                {/* <Grid item md={12} sm={12} xs={12}>
                </Grid> */}
            </Grid>
            <Grid container key="Footer">
                {/* <Footer>
                    Copyright &copy; 2020 INewsTamil. All rights reserved. 
                </Footer> */}
            </Grid>
        </>
    );
}