import { makeStyles, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { firestore } from './Firebase';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        height: '400px',
        [theme.breakpoints.down('sm')]: {
            height: '200px',
        }
    },
    articleBannerContainer: {
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
        height: "80%",
        background: "black",
        height: "80%",
        [theme.breakpoints.down('sm')]: {
            height: '60%',
        }
    },
    subContent: {
        height: '200px'
    }
}))

export default function PinnedHeadLines() {
    const classes = useStyles();
    const [pinnedHeadLines, setPinnedHeadLines] = useState([])
    const [loading, setloading] = useState(false)
    const fetchPinnedHeadLines = () => {
        firestore.collection('articles').orderBy("createdAt", "desc").limit(5).get().then(collections => {
            const articles = collections.docs.map(doc => ({articleData:doc.data(),Id:doc.id}))
            setPinnedHeadLines([...articles])
            setloading(false)
        })
        console.log(pinnedHeadLines);
    }

    useEffect(() => {
        setloading(true);
        fetchPinnedHeadLines();
    }, [])

    function MainContent(content) {
        return (<Card className={classes.mainContent}>
            <div className={classes.articleBannerContainer} style={{
            }}>
                <CardMedia className={classes.articleBanner}
                    component={content.data.articleData.banner.type.search("image") != -1 ? "img" : "iframe"}
                    alt=""
                    image={content.data.articleData.banner.url}
                    title=""
                    style={{
                        width:  "100%",
                        height: "100%"
                    }}
                />
            </div>
            <CardContent onClick={()=>{NavigateToArticle(content.data.Id)}} style={{cursor:"pointer"}}>
                <Typography style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical"
                }}>
                    {content.data.articleData.title}
                </Typography>
            </CardContent>
        </Card>)
    }
    function SubContent(content) {
        return (<Card className={classes.subContent}>
            <div style={{
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
                height: "60%",
                background: "black"
            }}>
                <CardMedia className={classes.articleBanner}
                    component={content.data.articleData.banner.type.search("image") != -1 ? "img" : "iframe"}
                    alt=""
                    image={content.data.articleData.banner.url}
                    title=""
                    style={{
                        width:  "100%",
                        height: "100%"
                    }}
                />
            </div>
            <CardContent onClick={()=>{NavigateToArticle(content.data.Id)}} style={{cursor:"pointer"}}>
                <Typography style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical"
                }}>
                    {content.data.articleData.title}
                </Typography>
            </CardContent>
        </Card>)
    }
    const history = useHistory();
    const NavigateToArticle = (articleId) => {
        console.log(articleId);
        history.push("/Article/"+articleId);
    }

    return (
        <Grid container key="PinnedHeadlinesGrid" style={{ background: "grey", padding: "25px" }}>
            {pinnedHeadLines.length > 0 && !loading &&
                (pinnedHeadLines.length == 1 &&
                    <Grid item md={12} sm={12} xs={12} style={{ alignItems: 'center' }}>
                        <MainContent data={pinnedHeadLines[0]} />
                    </Grid>)
                ||
                (pinnedHeadLines.length == 2 &&
                    (<Grid container spacing={1}><Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                        <MainContent data={pinnedHeadLines[0]} />
                    </Grid>
                        <Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                            <SubContent data={pinnedHeadLines[1]} />
                        </Grid></Grid>)
                    ||
                    (pinnedHeadLines.length == 3 &&
                        (<Grid container spacing={1}><Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                            <MainContent data={pinnedHeadLines[0]} />
                        </Grid>
                            <Grid container md={6} sm={12} xs={12} style={{ alignItems: 'center' }} spacin={1}>
                                <Grid item md={12} sm={12} xs={12} style={{ alignItems: 'center' }}>
                                    <SubContent data={pinnedHeadLines[1]} />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12} style={{ alignItems: 'center' }}>
                                    <SubContent data={pinnedHeadLines[2]} />
                                </Grid>
                            </Grid></Grid>))
                )
                ||
                (pinnedHeadLines.length == 4 &&
                    (<Grid container spacing={1}><Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                        <MainContent data={pinnedHeadLines[0]} />
                    </Grid>
                        <Grid container md={6} sm={12} xs={12} style={{ alignItems: 'center' }} spacing={1}>
                            <Grid item md={12} sm={12} xs={12} style={{ alignItems: 'center' }}>
                                <SubContent data={pinnedHeadLines[1]} />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                                <SubContent data={pinnedHeadLines[2]} />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                                <SubContent data={pinnedHeadLines[3]} />
                            </Grid>
                        </Grid></Grid>))
                ||
                (pinnedHeadLines.length == 5 &&
                    (<Grid container spacing={1}><Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                        <MainContent data={pinnedHeadLines[0]} />
                    </Grid>
                        <Grid container md={6} sm={12} xs={12} style={{ alignItems: 'center' }} spacing={1}>
                            <Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                                <SubContent data={pinnedHeadLines[1]} />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                                <SubContent data={pinnedHeadLines[2]} />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                                <SubContent data={pinnedHeadLines[3]} />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12} style={{ alignItems: 'center' }}>
                                <SubContent data={pinnedHeadLines[4]} />
                            </Grid>
                        </Grid></Grid>))
            }
        </Grid>
    )
}