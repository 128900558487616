import './App.css';
import Headlines from './Components/Headlines';
import Article from './Components/Article';
import NewsList from './Components/NewsList';
import Layout from './Layout';
import { Inewstheme } from './Theme'
import { ThemeProvider } from '@material-ui/core'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function handelRightClick(event) {
  event.preventDefault();
}

function App() {
  document.addEventListener('contextmenu', handelRightClick);
  return (
    <ThemeProvider theme={Inewstheme}>
      <Router>
        <Switch>
          <Route path="/Article/:articleId">
            <Layout>
              <Article />
            </Layout>
          </Route>
          <Route path="/NewsList/:category">
            <Layout>
              <NewsList/>
            </Layout>
          </Route>
          <Route path="/">
            <Layout>
              <Headlines />
            </Layout>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
