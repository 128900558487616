import { Container, makeStyles, Grid } from '@material-ui/core';
import faker from 'faker'
import {
    useParams
} from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { firestore, analytics } from './Firebase';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon,
     WhatsappShareButton, WhatsappIcon,TelegramShareButton,TelegramIcon} from "react-share";
import Typography from '@material-ui/core/Typography';
import HelmetMetaData from "./HelmetMetaData";
import { Instagram } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    scaffold: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        userSelect: "none",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(15),
        }
    },
    articleBanner: {
        maxWidth: "100%",
        height: "100%",
        objectFit: "cover"
    },
    gridList: {
        width: 500,
        height: 450,
    },
    gallery: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function Article() {
    let params = useParams();
    const [articleData, setArticleData] = useState({})
    const fetchArticleData = async (docId) => {
        await firestore.collection('articles').doc(docId).get().then(collections => {
            setArticleData(collections.data()); 
            analytics.logEvent(collections.data().title);
        })
    }

    useEffect(() => {
        fetchArticleData(params.articleId);
    }, [])

    const classes = useStyles();
    const data = {
        title: faker.lorem.sentences(1),
        image: faker.random.arrayElement([
            faker.image.nature(),
            faker.image.city(),
            faker.image.food()
        ]),
        description: faker.lorem.sentences(100)
    }

    function FormatArticle(article, gallery) {
        var mediaCount = gallery.length
        const ytTags = article.match(/(\<yt\>)(.*?)(\<yt\>)/g)
        var ytVids = []
        if(ytTags)
        {
            for(let tag of ytTags)
            {
                if(tag.match(/(\<yt\>)(.*?)\/watch\?v=(.*?)(\<yt\>)/g))
                {
                    ytVids.push(tag.replace(/(\<yt\>)(.*?)\/watch\?v=(.*?)(\<yt\>)/g,"$3"))
                }
                else if(tag.match(/(\<yt\>)(.*?)\.be\/(.*?)(\<yt\>)/g))
                {
                    ytVids.push(tag.replace(/(\<yt\>)(.*?)\.be\/(.*?)(\<yt\>)/g,"$3"))
                }    
            }
        }         
        article = article.replace(/(\<yt\>)(.*?)(\<yt\>)/gm,"")
        var ytInd = 0
        var sentences = article.replace(/(\.\n|\:|\!|\?)(\"*|\'*|\)*|}*|]*)(\s|\n|\r|\r\n)/gm, "$1$2|").split("|");         
        var paragraphs = []
        var i = 0
        return (sentences &&
            <Typography>
                {sentences.map((sentence, index) =>
                    <>
                    <Container disableGutters>
                        {(index > 2 && index % 1 == 0 && i < mediaCount ?
                            <Grid container>
                                <Grid item md={2} xs={0} sm={0}></Grid>
                                <Grid item md={8} xs={12} sm={12}>
                                    {(ytVids.length > 0 && ytInd < ytVids.length)?
                                    <>
                                    {/* Youtube Video {ytVids[ytInd++]} */}
                                    <Grid container>
                                        <Grid item md={2} xs={0} sm={0}></Grid>
                                        <Grid item md={8} xs={12} sm={12}>
                                            <iframe style={{width:"100%"}} height="500" src={"https://www.youtube.com/embed/"+ytVids[ytInd++]}></iframe>
                                        </Grid>
                                    </Grid>
                                    </>
                                    :
                                    <Card>
                                        <div style={{
                                            display: "flex",
                                            alignItem: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                            background: "black"
                                        }}>
                                            <CardMedia className={classes.articleBanner}
                                                component={gallery[i].type.search("image") != -1 ? "img" : "iframe"}
                                                alt=""
                                                image={gallery[i++].url}
                                                title=""
                                                style={{
                                                    width:  "100%",
                                                    height: "100%"
                                                }}
                                            />
                                        </div>
                                    </Card> 
                                    }
                                </Grid>
                            </Grid> : <></>)}
                        </Container>
                        <Container>
                            <span style={{justifyContent:'center', alignItems:'center'}} align="left">{sentence.split('<br>').map(str => <p>{str}</p>)}</span>
                        </Container>
                    </>
                )}
                { ytVids.map((ytVid, index)=>
                <>
                    {index>=ytInd &&
                    <>
                    <Grid container>
                        <Grid item md={2} xs={0} sm={0}></Grid>
                        <Grid item md={8} xs={12} sm={12}>
                            <iframe style={{width:"100%"}} height="500" src={"https://www.youtube.com/embed/"+ytVid}></iframe>
                        </Grid>
                    </Grid>
                    </>
                    }
                </>
                )}
                    {gallery.map((media, index) =>
                        <>
                            {i < mediaCount && index >= i &&
                            <>
                            <Grid container>
                                <Grid item md={2} xs={0} sm={0}></Grid>
                                <Grid item md={8} xs={12} sm={12}>
                                    <Card>
                                        <div style={{
                                            display: "flex",
                                            alignItem: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                            background: "black"
                                        }}>
                                            <CardMedia className={classes.articleBanner}
                                                component={gallery[i].type.search("image") != -1 ? "img" : "iframe"}
                                                alt=""
                                                image={gallery[i++].url}
                                                title=""
                                                style={{
                                                    width:  "100%",
                                                    height: "100%"
                                                }}
                                            />
                                        </div>
                                    </Card>
                                </Grid>
                                </Grid>
                                <p align="center">----</p>
                                </>
                                }
                        </>
                    )}
            </Typography>
        )
    }

    return (
        (articleData.title ?
            (<div>
                <HelmetMetaData
                    description={articleData.title}
                    image={articleData.banner.url}
                ></HelmetMetaData>
                <Container key="Article" className={classes.scaffold} disableGutters>
                    <Container>
                        <h1>{articleData.title}</h1>
                        <p>Published on {new Date(articleData.createdAt).toDateString()}</p>
                    </Container>
                    <Card style={{ height: "300px" }}>
                        <div style={{
                            display: "flex",
                            alignItem: "center",
                            justifyContent: "center",
                            height: "100%",
                            background: "black"
                        }}>
                            <CardMedia className={classes.articleBanner}
                                component={articleData.banner.type.search("image") != -1 ? "img" : "iframe"}
                                alt=""
                                image={articleData.banner.url}
                                title=""
                                style={{
                                    width:  "100%",
                                    height: "100%"
                                }}
                            />
                        </div>
                    </Card>
                    <Container>
                    <Grid container style={{ alignItems: "left" }} spacing={1}>
                        <Grid item>
                            <FacebookShareButton
                                url={window.location.href}
                                quote={articleData.title}
                                hashtag={"#" + articleData.category}
                            >
                                <FacebookIcon size={36} />
                            </FacebookShareButton></Grid>
                        <Grid item>
                            <TwitterShareButton
                                url={window.location.href}
                                title={articleData.title}
                                hashtag={"#" + articleData.category}
                            >
                                <TwitterIcon size={36} />
                            </TwitterShareButton></Grid>
                        <Grid item>
                            <WhatsappShareButton
                                url={window.location.href}
                                title={articleData.title}
                            >
                                <WhatsappIcon size={36} />
                            </WhatsappShareButton></Grid>
                        <Grid item>
                            <TelegramShareButton
                                url={window.location.href}
                                title={articleData.title}
                            >
                                <TelegramIcon size={36} />
                            </TelegramShareButton></Grid>
                    </Grid>
                    </Container>
                    <Container disableGutters>
                    {FormatArticle(articleData.fullstory, articleData.gallery)}
                    </Container>
                </Container ></div>) : <></>)
    );

}