import { Container, makeStyles, Grid} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory,useParams } from "react-router-dom";
import { firestore } from './Firebase';

const useStyles = makeStyles((theme) => ({
    scaffold: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        userSelect:"none",
        WebkitUserSelect:"none",
        MozUserSelect:"none",
        msUserSelect:"none",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(15),
        }
    },
    article: {
        height: '300px',
    },
    advertisement: {
        height: 'auto',
    },
    articleBanner: {
        maxWidth: "100%",
        height: "100%",
        objectFit: "cover"
    },
    articleTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical"
    },
    articleStory: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical"
    },
}));

export default function NewsList() {
    let lastAdDoc = {}
    let adInd = 0
    const [adImgUrl, setAdImgUrl] = useState([]);
    
    let params = useParams();
    const [articles, setArticles] = useState([])
    let lastDoc = {}
    const [hasMore, setHasMore] = useState(true)
    const fetchData = async () => {
        console.log(params.category)
        firestore.collection('articles').where("category","==",params.category).orderBy("createdAt", "desc").limit(10).get().then(collections => {
            const articles1 = collections.docs.map(doc => ({articleData:doc.data(),Id:doc.id}))
            setArticles([...articles1])
            lastDoc = collections.docs[collections.docs.length - 1]
        })

    }
    const fetchData2 = async () => {
        console.log(params.category)
        console.log(lastDoc.id)
        firestore.collection('articles').where("category","==",params.category).orderBy("createdAt", "desc").startAfter(lastDoc).limit(10).get().then(collections => {
            if (collections.docs.length != 0) {
                setArticles(prevArticles => [...prevArticles, ...collections.docs.map(doc => ({articleData:doc.data(),Id:doc.id}))])
                lastDoc = collections.docs[collections.docs.length - 1]
                setLoading(false);
                console.log(lastDoc.id)
            }
            else {
                setHasMore(false);
                setLoading(false);
            }
        })

    }
    useEffect(async () => {
        fetchData();
    }, [params.category])
    const classes = useStyles();
    const history = useHistory();

    const observer = useRef();
    const [loading, setLoading] = useState(false);
    const lastArticleElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                getMoreData();
            }
        });
        if (node) observer.current.observe(node);
        console.log(node);
    }, [hasMore]);

    const getMoreData = async () => {
        setLoading(true);
        await fetchData2()
    }

    const NavigateToArticle = (articleId) => {
        console.log(articleId);
        history.push("/Article/"+articleId);
    }

    const getAd = async() =>{
        if(adImgUrl.length != 0)
            return;
        await firestore.collection('advertisement').orderBy("priority", "desc").get().then(collections => {
            const articles1 = collections.docs.map(doc => ({ ...doc.data(), Id: doc.id }))
            lastAdDoc = collections.docs[collections.docs.length - 1]
            setAdImgUrl([...articles1])
            console.log(articles1)
            //console.log("In here2")
            //console.log(articles1)
        })
        //console.log("In here resul")
        console.log("what is")
        console.log(adImgUrl)
        //console.log(lastAdDoc)
        return true
    }

    return (
        <Container key="NewsListContainer" className={classes.scaffold} spacing={2}  maxWidth={false}>
            <Grid container key="NewsListGrid" spacing={2}>
                {articles.length==0 && (<Grid item md={12} sm={12} xs={12} style={{textAlign:"center"}}>No News found under this category</Grid>)}
                {articles && articles.map((data, index) =>
                    <>
                        {(index != 0 && index % 16 == 0 && getAd() && (adImgUrl.length) &&
                            <Grid container>
                            <Grid item md={0} sm={0}></Grid>
                            <Grid item md={12} sm={12} style={{ margin: "20px 0px" }}>
                                {/* <img src={adImgUrl} style={{Width: "100vh", maxHeight: "100%"}}/> */}
                                <Card className={classes.advertisement}>
                                    <div style={{
                                        display: "flex",
                                        alignItem: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                        background: "black"
                                    }}>
                                        <CardMedia className={classes.articleBanner}
                                            component= "img" 
                                            alt=""
                                            image={adImgUrl[(adInd++)%adImgUrl.length].img}
                                            title=""
                                            style={{
                                                width: "100%",
                                                height: "100%"
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Grid></Grid>)}
                        <Grid item md={3} sm={12} xs={12} key={data.Id}
                            {...(articles.length == index + 1 ? { ref: lastArticleElementRef } : {})}
                        >
                            <Card className={classes.article}>
                                <div style={{
                                    display: "flex",
                                    alignItem: "center",
                                    justifyContent: "center",
                                    height: "50%",
                                    background: "black"
                                }}>
                                    <CardMedia className={classes.articleBanner}
                                        component={data.articleData.banner.type.search("image") != -1 ? "img" : "iframe"}
                                        alt=""
                                        image={data.articleData.banner.url}
                                        title=""
                                        style={{
                                            width:  "100%",
                                            height: "100%",
                                        }}
                                    />
                                </div>
                                <CardContent onClick={()=>{NavigateToArticle(data.Id)}} style={{padding:"10px",cursor:"pointer"}}>
                                    <Typography variant="h6" className={classes.articleTitle}>
                                        {data.articleData.title}
                                    </Typography>
                                    <Typography className={classes.articleStory}>
                                        {data.articleData.fullstory}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                )}
            </Grid>
            <div>{loading && 'Loading...'}</div>
        </Container >
    );
}